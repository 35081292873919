import React, { useEffect, useState } from 'react';
import {
    Grid,
    Button,
    Card,
    CardContent,
    Typography,
    Box,
    Divider,
} from '@mui/material';
import styled from 'styled-components';
import Profile from '../../../assets/detailcampaign/influencer_profile_photo.png';
import PrimaryBtn from '../../CustomButton/PrimaryBtn';
import SnapchatIcon from '../../../assets/detailcampaign/Snapchat.png';
import InstagramIcon from '../../../assets/detailcampaign/Instagram.png';
import TikTokIcon from '../../../assets/detailcampaign/Tiktok.png';
import dev from '../../../services/axios-client';
import { useNavigate, useParams } from 'react-router-dom';
import FormRejectionDialog from '../../Popups/formRejectionDialog';
import FormSubmitDialog from '../../Popups/formSubmitDialog';
import Loader from '../../Loader';

const IconStyle = {
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #F1F4EF',
    alignItems: 'center',
    fontSize: '24px' /* Adjust font size as needed */,
    fontWeight: 'bold' /* Adjust font weight as needed */,
    textAlign: 'center' /* Center text horizontally */,
    lineHeight: '47px' /* Center text vertically */,
};

const StyledCard = styled(Card)`
    min-height: 213px;
    width: 293px;
    padding: 10px;
    text-align: center;
`;

const TopSection = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
`;
const TopSectionCards = styled(Box)`
    display: flex;
    margin-bottom: 20px;
    padding: 10px;
    gap: 20px;
`;

const StatsCard = styled(Box)`
    background-color: ${(props) => props.bgColor};
    color: white;
    border-radius: 8px;
    text-align: start;
    width: 230px;
    height: 92px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding-left: 20px;
    gap: 5px;
`;

const ResponsiveGrid = styled(Grid)`
    margin-bottom: 40px;
`;

const InfluencerCard = ({ influencer, pending, getCampaign }) => {
    const navigate = useNavigate();
    let { campaignId } = useParams();
    const user = JSON.parse(localStorage.getItem('user'));
    const [responseModal, setResponseModal] = useState({ open: false });

    const handleResponseClose = () => {
        setResponseModal({ open: false });
        getCampaign();
    };

    const ApproveInfluencer = async (influencerId) => {
        await dev
            .post(
                `/api/v1/campaign/approve/influencer/${campaignId}/${influencerId}`,
                {},
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response) {
                    setResponseModal({
                        open: true,
                        error: false,
                        title: 'Influencer approved successfully',
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: 'There was an error approving influencer',
                });
            });
    };
    const rejectInfluencer = async (influencerId) => {
        await dev
            .post(
                `/api/v1/campaign/reject/influencer/${campaignId}/${influencerId}`,
                {},
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response) {
                    setResponseModal({
                        open: true,
                        error: false,
                        title: 'Influencer reject successfully',
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: 'There was an error rejecting influencer',
                });
            });
    };
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'20px'}
            alignItems={'center'}
        >
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <StyledCard
                style={{
                    height: pending ? '320px' : '263px',
                    width: '213px',
                }}
            >
                <img
                    width={168}
                    height={123}
                    src={influencer.profile ? influencer.profile : Profile}
                    alt={influencer.name}
                    style={{
                        objectFit: 'contain',
                    }}
                />
                <Box sx={{ paddingInline: '20px' }}>
                    <Box display="flex" justifyContent={'space-between'} mt={3}>
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                fontSize: '14.49px',
                                fontWeight: 600,
                                lineHeight: '18.26px',
                            }}
                        >
                            {influencer.name}
                        </Typography>

                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    lineHeight: '17.64px',
                                }}
                            >
                                {influencer.followers}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '10.47px',
                                    fontWeight: 500,
                                    lineHeight: '13.26px',
                                }}
                            >
                                Followers
                            </Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ mt: 1 }} />
                    {pending && (
                        <>
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                mt={2}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontSize: '9.66px',
                                        fontWeight: 600,
                                        lineHeight: '18.26px',
                                        color: '#808080',
                                    }}
                                >
                                    Enrollment Date
                                </Typography>

                                <Typography
                                    sx={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontSize: '9.66px',
                                        fontWeight: 500,
                                        lineHeight: '13.26px',
                                        color: '#808080',
                                    }}
                                >
                                    {influencer?.date}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                mt={1}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontSize: '9.66px',
                                        fontWeight: 600,
                                        lineHeight: '18.26px',
                                        color: '#808080',
                                    }}
                                >
                                    Enrollment Time
                                </Typography>

                                <Typography
                                    sx={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontSize: '9.66px',
                                        fontWeight: 600,
                                        lineHeight: '17.64px',
                                        color: '#808080',
                                    }}
                                >
                                    {influencer?.time}
                                </Typography>
                            </Box>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                gap={'10px'}
                            >
                                {influencer?.platforms?.includes(
                                    'snapchat',
                                ) && (
                                    <Box style={IconStyle}>
                                        <img
                                            src={SnapchatIcon}
                                            alt={''}
                                            width={10}
                                            height={10}
                                        />
                                    </Box>
                                )}
                                {influencer?.platforms?.includes(
                                    'instagram',
                                ) && (
                                    <Box style={IconStyle}>
                                        <img
                                            src={InstagramIcon}
                                            alt={''}
                                            width={10}
                                            height={10}
                                        />
                                    </Box>
                                )}
                                {influencer?.platforms?.includes('tiktok') && (
                                    <Box style={IconStyle}>
                                        <img
                                            src={TikTokIcon}
                                            alt={''}
                                            width={10}
                                            height={10}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                    <PrimaryBtn
                        text="View Profile"
                        onClick={() =>
                            navigate(`/influencer-profile/${influencer?.id}`)
                        }
                        fontStyle={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '10px',
                            fontWeight: 500,
                        }}
                        bgColor="#D3F26A"
                        color="#000000"
                        style={{
                            width: !pending ? '100%' : '92.59px',
                            height: !pending ? '38.15px' : '24.15px',
                            border: 'unset',
                            mt: 2,
                        }}
                    />
                </Box>
            </StyledCard>
            {pending && (
                <Box display={'flex'} gap={'10px'}>
                    <PrimaryBtn
                        text="Reject"
                        onClick={() =>
                            rejectInfluencer(influencer?.campaingInfluencerId)
                        }
                        fontStyle={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '10px',
                            fontWeight: 500,
                        }}
                        bgColor="#FF8692"
                        color="#000000"
                        style={{
                            width: '80.94px',
                            height: '27.15px',
                            border: 'unset',
                        }}
                    />
                    <PrimaryBtn
                        text="Approve"
                        onClick={() =>
                            ApproveInfluencer(influencer?.campaingInfluencerId)
                        }
                        fontStyle={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '10px',
                            fontWeight: 500,
                        }}
                        bgColor="#07E098"
                        color="#000000"
                        style={{
                            width: '80.94px',
                            height: '27.15px',
                            border: 'unset',
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

const CampaignInfluencers = () => {
    let { campaignId } = useParams();

    const [loading, setLoading] = useState(false);
    const [campaign, setCampaign] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));

    console.log({ campaign });

    useEffect(() => {
        campaignId && getCampaign();
    }, [campaignId]);

    const getCampaign = async () => {
        setLoading(true);
        await dev
            .get(`/api/v1/campaign/single/${campaignId}`, {
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                if (response.data) {
                    setCampaign(response.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    return (
        <Box p={3}>
            <Loader loading={loading} />
            <TopSection>
                <Typography
                    sx={{
                        fontFamily: 'Red Hat Display',
                        fontWeight: 500,
                        fontSize: '26px',
                    }}
                >
                    {campaign?.title}
                </Typography>
                <Typography variant="body2">
                    Campaign ID: {campaignId}
                </Typography>
            </TopSection>

            <TopSectionCards>
                <StatsCard bgColor="#1f1f1f">
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '20.52px',
                            letterSpacing: '-2%',
                        }}
                    >
                        {campaign?.posts}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '16px',
                            letterSpacing: '-2%',
                        }}
                    >
                        Total Posts done
                    </Typography>
                </StatsCard>
                <StatsCard bgColor="#1f1f1f">
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '20.52px',
                            letterSpacing: '-2%',
                        }}
                    >
                        {campaign?.enrolledInfluencers.length}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '16px',
                            letterSpacing: '-2%',
                        }}
                    >
                        Influencers Enrolled
                    </Typography>
                </StatsCard>
                <StatsCard bgColor="#1e90ff">
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '20.52px',
                            letterSpacing: '-2%',
                        }}
                    >
                        {campaign?.engagement}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '16px',
                            letterSpacing: '-2%',
                        }}
                    >
                        Total Engagement
                    </Typography>
                </StatsCard>
            </TopSectionCards>

            <Typography
                sx={{
                    fontFamily: 'Red Hat Display',
                    fontWeight: 600,
                    fontSize: '24px',
                    letterSpacing: '-3%',
                }}
            >
                Enrolled Influencers
            </Typography>
            {campaign?.enrolledInfluencers.length > 0 ? (
                <ResponsiveGrid container spacing={2} mt={2}>
                    {campaign?.enrolledInfluencers?.map((influencer, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            xl={2}
                            key={index}
                        >
                            <InfluencerCard
                                getCampaign={getCampaign}
                                influencer={influencer}
                                pending={false}
                            />
                        </Grid>
                    ))}
                </ResponsiveGrid>
            ) : (
                <Typography variant="body">
                    There are no influencers enrolled right now
                </Typography>
            )}

            <Typography
                sx={{
                    fontFamily: 'Red Hat Display',
                    fontWeight: 600,
                    fontSize: '24px',
                    letterSpacing: '-3%',
                }}
            >
                Pending Approvals
            </Typography>
            {campaign?.pendingInfluencers.length > 0 ? (
                <ResponsiveGrid container spacing={2} mt={2}>
                    {campaign?.pendingInfluencers?.map((influencer, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            xl={2}
                            key={index}
                        >
                            <InfluencerCard
                                getCampaign={getCampaign}
                                influencer={influencer}
                                pending={true}
                            />
                        </Grid>
                    ))}
                </ResponsiveGrid>
            ) : (
                <Typography variant="body">
                    There are no influencers pending for approvals
                </Typography>
            )}
        </Box>
    );
};

export default CampaignInfluencers;
